import styled, { css } from 'styled-components';

import { ErrorNotificationWrapper } from 'src/components/ErrorNotification/error-notification-styled';
import { HHero, H1, Text1Bold } from 'src/components/Text';
import { smMedia, mdMedia, themeColor } from 'src/theme/utils';

export const StepChronoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  margin-bottom: 24px;
  ${HHero} {
    display: none;
  }
  ${H1} {
    display: flex;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  ${Text1Bold} {
    text-align: left;
  }
  ${ErrorNotificationWrapper} {
    margin: 16px 0;
  }
  ${mdMedia(css`
    ${HHero} {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 8px;
    }
    ${H1} {
      display: none;
    }
    ${Text1Bold} {
      text-align: center;
    }
  `)}
`;

export const StepChronoChoices = styled.div`
  display: grid;

  grid-template:
    'item1 item2'
    'item3 item4'
    'item5 item5';
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  justify-content: space-between;
  column-gap: 16px;
  row-gap: 8px;
  margin-top: 22px;
  ${smMedia(css`
    grid-template:
      'item1 item2 item3 item4'
      'item5 item5 . .';
    margin-top: 40px;
  `)}

  ${mdMedia(css`
    grid-template: 'item1 item2 item3 item4 item5';
    row-gap: 0px;
    margin-top: 56px;
  `)}
`;

export const StepChronoChoiceItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 189px;
  border-radius: 7px;
  margin-bottom: 16px;
  &:nth-child(1) {
    grid-area: item1;
    background: #f0f8ff;
    button {
      background: #bfe1fc;
    }
  }
  &:nth-child(2) {
    grid-area: item2;
    background: #fff7df;
    button {
      background: #fee9a6;
    }
  }
  &:nth-child(3) {
    grid-area: item3;
    background: #eaf7f6;
    button {
      background: #a0e0db;
    }
  }
  &:nth-child(4) {
    grid-area: item4;
    background: #fff2ee;
    button {
      background: #fed3c5;
    }
  }
  &:nth-child(5) {
    grid-area: item5;
    height: 56px;
    width: 100%;
    background: ${themeColor('black5')};
    margin-top: -8px;
    button {
      border-radius: 7px;
      background: ${themeColor('black10')};
    }
    & > div {
      display: none;
    }
  }
  ${smMedia(css`
    width: 160px;
  `)}

  ${mdMedia(css`
    width: 200px;
    height: 306px;
    margin-bottom: 0;
    &:nth-child(5) {
      height: 306px;
      width: 200px;
      background: ${themeColor('black5')};
      margin-top: 0;
      button {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        background: ${themeColor('black10')};
      }
      & > div {
        display: flex;
      }
      img {
        display: flex;
        height: 96px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  `)}
`;

export const ChronoImg = styled.div`
  display: flex;
  max-height: 133px;
  height: 100%;
  cursor: pointer;
  img {
    height: calc(100% + 16px);
    margin-top: -16px;
    margin-left: auto;
    margin-right: auto;
  }
  ${mdMedia(css`
    max-height: 250px;
  `)}
`;

export const ChronoBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  border: none;
  outline: none;
  height: fit-content;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 16px;
  height: 56px;
  &[disabled] {
    cursor: not-allowed;
  }
  &:hover {
    opacity: 0.5;
  }
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  margin: 1.5rem auto 0;
  max-width: 556px;
  ${smMedia(css`
    margin-left: 0;
  `)}
  ${mdMedia(css`
    margin: 2.5rem 0 0;
  `)}
`;

export const PrivacyCheckboxSVG = styled.svg`
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 0.5rem;
  cursor: pointer;
  & > path {
    stroke: transparent;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke 100ms ease-in-out;
  }
  & > rect {
    stroke: ${themeColor('black20')};
    stroke-width: 2px;
    transition: stroke 100ms ease-in-out;
  }
  &.checked {
    & > path {
      stroke: ${themeColor('primary50')};
    }
    & > rect {
      stroke: transparent;
    }
  }
`;

export const EmailConfirmWrapper = styled.div`
  display: flex;
  margin: 1rem auto 0;
  max-width: 556px;
  ${smMedia(css`
    margin-left: 0;
  `)}
  ${mdMedia(css`
    margin: 1rem 0 0;
  `)}
`;

export const EmailConfirmCheckboxSVG = styled.svg`
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 0.5rem;
  cursor: pointer;
  & > path {
    stroke: transparent;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke 100ms ease-in-out;
  }
  & > rect {
    stroke: ${themeColor('black20')};
    stroke-width: 2px;
    transition: stroke 100ms ease-in-out;
  }
  &.checked {
    & > path {
      stroke: ${themeColor('primary50')};
    }
    & > rect {
      stroke: transparent;
    }
  }
`;
